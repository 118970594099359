import { Injectable } from '@angular/core';

import { getFirebaseBackend } from '../../authUtils';

import { User } from '../models/auth.models';
import { KlippyService } from 'src/app/klippy.service';

@Injectable({ providedIn: 'root' })

export class AuthenticationService {
    //srvc = getFirebaseBackend();
    user: User;

    constructor(private srvc: KlippyService) {
    }

    /**
     * Returns the current user
     */
    public currentUser(): User {        
        if (!sessionStorage.getItem('authUser')) {
            return null;
        }
        return JSON.parse(sessionStorage.getItem('authUser'));
    }

    setLoggeedInUser = (user) => {
        sessionStorage.setItem('authUser', JSON.stringify(user));
    }

    /**
     * Performs the auth
     * @param email email of user
     * @param password password of user
     */
    login(email: string, password: string) {
        // return this.srvc.loginUser(email, password).then((response: any) => {
        //     const user = response;
        //     return user;
        // });        
    }

    /**
     * Performs the register
     * @param email email
     * @param password password
     */
    register(email: string, password: string) {
        // return this.srvc.registerUser(email, password).then((response: any) => {
        //     const user = response;
        //     return user;
        // });
        return null;
    }

    /**
     * Reset password
     * @param email email
     */
    resetPassword(email: string) {
        // return this.srvc.forgetPassword(email).then((response: any) => {
        //     const message = response.data;
        //     return message;
        // });
        return null;
    }

    /**
     * Logout the user
     */
    logout() {
        // logout the user
        sessionStorage.clear();
    }
}

