import { HttpClient } from "@angular/common/http";

export class KlippyBase {
    constructor(http: HttpClient) { }

    getLast12Months() {
        let dd = [];
        for (var i = 0; i < 12; i++) {
            var d = new Date();
            d.setMonth(d.getMonth() - i);
            var month = d.toLocaleString("default", { month: "short" });
            var year = d.getFullYear();
            var monthNo = d.getMonth();
            if (monthNo < 9) {
                dd.push({ value: (monthNo + 1) + "_" + year, text: month + "-" + year });
            } else {
                dd.push({ value: (monthNo + 1) + "_" + year, text: month + "-" + year });
            }
        }
        return dd;
    }

    getDateRange(val) {
        const dt = val.split("_");
        let mm = Number(dt[0]);
        let yy = Number(dt[1]);
        let firstDay = new Date(yy, mm - 1, 1);
        let lastDay = new Date(yy, mm, 0);
        let dateRange = {

            "firstDay": this.formatDate(firstDay),
            "lastDay": this.formatDate(lastDay)
        }
        return dateRange;
    }

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }
    ////////////////////////////////////////////////////////////////////

    /**let arrHeader = ["visitDate", "passCount", "passCost", "tipAmount"];
        let csvData = this.ConvertToCSV(data, arrHeader); */
    downloadFile(csvData, filename = 'sample') {

        //console.log(csvData)
        let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
        let dwldLink = document.createElement("a");
        let url = URL.createObjectURL(blob);
        let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
        if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
            dwldLink.setAttribute("target", "_blank");
        }
        dwldLink.setAttribute("href", url);
        dwldLink.setAttribute("download", filename + ".csv");
        dwldLink.style.visibility = "hidden";
        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);
    }


    convertSingleRow(objRow, headerList) {
        console.log(objRow);
        console.log(headerList);
        let array = typeof objRow != 'object' ? JSON.parse(objRow) : objRow;
        let str = '';
        let row = 'S.No,';

        for (let index in headerList) {
            row += headerList[index] + ',';
        }
        row = row.slice(0, -1);
        str += row + '\r\n';

        let line = (1) + '';
        for (let index in headerList) {
            let head = headerList[index];

            line += ',' + this.strRep(array[head]);
        }
        str += line + '\r\n';
        return str;
    }

    convertToCSV(objArray, headerList) {
        //console.log(objArray);
        //console.log(headerList);
        let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
        let row = 'S.No,';

        for (let index in headerList) {
            row += headerList[index] + ',';
        }
        row = row.slice(0, -1);
        str += row + '\r\n';
        for (let i = 0; i < array.length; i++) {
            let line = (i + 1) + '';
            for (let index in headerList) {
                let head = headerList[index];

                line += ',' + this.strRep(array[i][head]);
            }
            str += line + '\r\n';
        }
        return str;
    }


    strRep(data) {
        if (typeof data == "string") {
            let newData = data.replace(/,/g, " ");            
            return newData;
        }
        else if (typeof data == "undefined") {
            return "-";
        }
        else if (typeof data == "number") {
            return data.toString();
        }
        else {
            if (data)
                return data;
            else 
                return "-";
        }
    }
}
