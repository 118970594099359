<footer class="footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                {{year}} © Klippy.
            </div>
            <div class="col-sm-6">
                <div class="text-sm-end d-none d-sm-block">
                    Design & Develop by <a href='https://digiflute.com/'>Digiflute</a>
                </div>
            </div>
        </div>
    </div>
</footer>